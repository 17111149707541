<template>
  <div id="content">
    <PublicHeader :style="{ marginBottom: '8px' }" />
    <transition name="fade">
      <Card :dis-hover="true" class="no_select">
        <p>请选择考试场次：</p>
        <Cascader
          :data="basics"
          :load-data="loadBasic"
          @on-change="handleChange"
        />
        <div id="content">
          <router-view :key="activeDate"></router-view>
        </div>
      </Card>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import { Modal } from "view-ui-plus";
import PublicHeader from "./PublicHeader.vue";
const _axios = axios.create({
  baseURL: "//yun1.hbgs.online/kaoshi",
  timeout: 3000,
  headers: { "content-type": "application/x-www-form-urlencoded" },
});
_axios.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => Promise.reject(error)
);
_axios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);
export default {
  name: "HelloWorld",
  components: {
    PublicHeader,
  },
  created() {
    if (this.$route.meta?.userid == null) {
      this.$router.replace({ path: "/Login" });
    } else {
      console.log(this.$route.meta?.userid);
      let userid = (this.userid = this.$route.meta?.userid);
      this.username = this.$route.meta?.username;
      _axios
        .post("index.php?content-phone-muinews-findbasic", { userid })
        .then((resp) => {
          let basics = [...resp.basics];
          for (let item of basics) {
            let basic = {
              value: item.basicid,
              label: item.basic,
              children: [],
              loading: false,
              basicareaid: item.basicareaid,
              basicareaid2: item.basicareaid2,
              basicclosed: item.basicclosed,
              basicdescribe: item.basicdescribe,
              basicid: item.basicid,
              basicknows: item.basicknows,
              basicsection: item.basicsection,
              basicshowday: item.basicshowday,
              basicshowtime: item.basicshowtime,
              basicsubjectid: item.basicsubjectid,
              basicthumb: item.basicthumb,
              basiczt: item.basiczt,
              groupid: item.groupid,
              pxorder: item.pxorder,
            };
            this.basics.push(basic);
          }
        });
    }
  },
  mounted() {},
  data() {
    return {
      activeDate: new Date().getTime(),
      userid: "",
      username: "",
      basics: [],
      basicsName: [{ value: "", label: "考场名称" }],
    };
  },
  methods: {
    async loadBasic(item, callback) {
      item.loading = true;
      item.children.length = 0;
      let isVip = !!this.username
        .toLowerCase()
        .match(/^((jh063[04])|004283|jc1536|jh0236)$/gi);
      setTimeout(() => {
        item.children.push({ value: "tiku", label: "全部题库" });
      }, 1000);
      let data1 = { basicid: item.basicid, banben: "20230511" };
      isVip &&
        (await _axios(
          "index.php?content-phone-muinews-getexaminfotime",
          data1
        ).then((resp) => {
          setTimeout(() => {
            resp.statusCode == 200 &&
              isVip &&
              item.children.push({ value: "1", label: "模拟考试" });
          }, 1000);
        }));
      let data2 = {
        userid: this.userid,
        basicid: item.basicid,
        ehtype: 2,
        banben: "20230511",
      };
      await _axios
        .post("index.php?content-phone-muinews-getexaminfonews", data2)
        .then((resp) => {
          setTimeout(() => {
            resp.statusCode == 200 &&
              isVip &&
              item.children.push({ value: "2", label: "正式考试" });
            item.loading = false;
            callback();
          }, 1000);
        });
    },
    handleChange(value, selectedData) {
      let basicid = value[0];
      let ehtype = value[1];
      let item = selectedData[0];
      if (!item) {
        this.$router.replace({ name: "home" });
        return;
      }
      let basicknows = [];
      for (let key in item.basicknows) {
        let section = item.basicknows[key];
        for (let knowid in section) {
          basicknows.push(knowid);
        }
      }
      // 获取章节题目id
      switch (ehtype) {
        case "1": {
          Modal.info({
            title: "模拟考试",
            content: "模拟考试机答案仅供参考，不保证完全正确，请酌情使用。",
            okText: "我已知晓",
            onOk: () => {
              // 确认后回调
              this.$router.beforeEach(async (to, from, next) => {
                to.meta = { userid: this.userid, basicid, basicknows, ehtype };
                next();
              });
              this.$router.replace({
                name: "exam",
                query: {
                  t: new Date().getTime(),
                },
              });
            },
          });
          break;
        }
        case "2": {
          Modal.info({
            title: "正式考试",
            content:
              "正式考试机会只有一次，答案仅供参考，不保证完全正确，请酌情使用。",
            okText: "我已知晓",
            onOk: () => {
              // 确认后回调
              this.$router.beforeEach(async (to, from, next) => {
                to.meta = { userid: this.userid, basicid, basicknows, ehtype };
                next();
              });
              this.$router.replace({
                name: "exam",
                query: {
                  t: new Date().getTime(),
                },
              });
            },
          });
          break;
        }
        default: {
          this.$router.beforeEach(async (to, from, next) => {
            to.meta = { userid: this.userid, basicid, basicknows, ehtype };
            next();
          });
          this.$router.replace({
            name: "lesson",
            query: {
              t: new Date().getTime(),
            },
          });
          break;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.no_select {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
</style>
