<template>
  <transition name="fade">
    <PageHeader :title="usertruename + '，你好!'" :logo="logo">
      <template
        #action
        v-if="
          [
            '黄彪',
            '门泽斌',
            '张敏捷',
            '刘妍',
            '李海强',
            '刘姗姗',
            '李建琦',
            '张雅军',
            '陈子敬',
            '李嘉',
          ].includes(usertruename) &&
          !(
            tasklogin > 0 &&
            taskcontent == taskcontentmax &&
            taskcourse == taskcoursemax
          )
        "
        ><Button type="primary" @click="handleStudy">开始学习</Button></template
      >
      <template #content>
        <DescriptionList :col="3">
          <Description term="单位：">{{ usercomname }}</Description>
          <Description term="部门：">{{ userstaname }}</Description>
          <Description term="职务：">{{ usergroupname }}</Description>
          <Description term="季度积分："
            ><CountUp :end="userjftxnum" :duration="duration"
          /></Description>
          <Description term="年度积分："
            ><CountUp :end="userjftx2num" :duration="duration"
          /></Description>
          <Description term="总积分："
            ><CountUp :end="userzjftxnum" :duration="duration"
          /></Description>
          <Description :term="taskloginname + '积分：'"
            ><CountUp :end="tasklogin" :duration="duration" />/{{
              taskloginmax
            }}</Description
          >
          <Description :term="taskcontentname + '积分：'"
            ><CountUp :end="taskcontent" :duration="duration" />/{{
              taskcontentmax
            }}</Description
          >
          <Description :term="taskcoursename + '积分：'"
            ><CountUp :end="taskcourse" :duration="duration" />/{{
              taskcoursemax
            }}</Description
          >
        </DescriptionList>
      </template>
      <template #extra>
        <p style="color: #808695">状态</p>
        <p style="font-size: 24px">
          {{
            tasklogin > 0 &&
            taskcontent == taskcontentmax &&
            taskcourse == taskcoursemax
              ? "学习完成"
              : "未完成"
          }}
        </p>
      </template>
    </PageHeader>
  </transition>
</template>

<script setup>
import axios from "axios";
import qs from "qs";
import { Message, Spin } from "view-ui-plus";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const _axios = axios.create({
  baseURL: "//yun1.hbgs.online/kaoshi",
  timeout: 3000,
  headers: { "content-type": "application/x-www-form-urlencoded" },
});
_axios.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => Promise.reject(error)
);
_axios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);

const route = useRoute();
const router = useRouter();

const duration = ref(2);

const userid = ref(route.meta.userid);
const usertruename = ref("用户");
const usercomname = ref(null);
const userstaname = ref(null);
const usergroupname = ref(null);
const logo = ref("/favicon.png");
const userjftxnum = ref(0);
const userjftx2num = ref(0);
const userzjftxnum = ref(0);
const taskloginname = ref("登录");
const tasklogin = ref(0);
const taskloginmax = ref(0);
const taskcontentname = ref("新闻");
const taskcontent = ref(0);
const taskcontentmax = ref(0);
const taskcoursename = ref("学习");
const taskcourse = ref(0);
const taskcoursemax = ref(0);

const getsetting = async (userid) => {
  if (!userid) {
    router.replace({ path: "/Login" });
    return;
  }
  let data = { userid, lastpage: 1 };
  await _axios
    .post("index.php?content-phone-muinews-getsetting", data)
    .then((resp) => {
      // console.log(resp);
      if (resp.statusCode == 200) {
        let args = resp.args;
        usertruename.value = args.usertruename;
        usercomname.value = args.usercomname;
        userstaname.value = args.userstaname;
        usergroupname.value = args.usergroupname;
        logo.value = `//yun1.hbgs.online/kaoshi/${args.photo}`;
        userjftxnum.value = parseInt(args.userjftxnum);
        userjftx2num.value = parseInt(args.userjftx2num);
        userzjftxnum.value = parseInt(args.userzjftxnum);
      }
    });
  gettasklist(userid);
};

const gettasklist = async (userid) => {
  if (!userid) {
    router.replace({ path: "/Login" });
    return;
  }
  await _axios
    .post("index.php?content-phone-muinews-gettasklist", { userid: userid })
    .then((resp) => {
      // console.log(resp);
      if (resp.statusCode == 200) {
        let tasks = resp.tasks;
        taskloginname.value = tasks[0].taskname;
        tasklogin.value = parseInt(tasks[0].scores);
        taskloginmax.value = tasks[0].taskmax;
        taskcontentname.value = tasks[1].taskname;
        taskcontent.value = parseInt(tasks[1].scores);
        taskcontentmax.value = tasks[1].taskmax;
        taskcoursename.value = tasks[2].taskname;
        taskcourse.value = parseInt(tasks[2].scores);
        taskcoursemax.value = tasks[2].taskmax;
      }
    });
};

//获取登录积分

const getlogingrade = async () => {
  if (!userid.value) {
    router.replace({ path: "/Login" });
    return;
  }
  await _axios
    .post("index.php?content-phone-muinews-getlogingrade", {
      userid: userid.value,
    })
    .then(() => getsetting(userid.value));
};

// 获取新闻积分

const getcontentgrade = async () => {
  if (!userid.value) {
    router.replace({ path: "/Login" });
    return;
  } else {
    let data = {
      userid: userid.value,
      column:
        "contentid,contenttitle,contentthumb,contentusername,contentinputtime,contentwatch,contentfavor,clogtime,contentzt,contentpdf",
      lastpage: 1,
      xwfl: 1,
      catid: 21,
    };
    await _axios
      .post("index.php?content-phone-muislider-getContentsBycatid", data)
      .then(async (resp) => {
        if (resp.statusCode == 200) {
          let contents = resp.contents.data;
          for (let content of contents) {
            let contentid = content.contentid;
            let title = content.contenttitle;
            let watchandfavordata = { userid: userid.value, contentid };
            let getcontentgradedata = { userid: userid.value, title };
            let delaytime = 1000 + Math.floor(Math.random() * 1000);
            await delay(delaytime);
            await _axios.post(
              "index.php?content-phone-muinews-findbannerforwatchandfavor",
              watchandfavordata
            );
            await _axios.post(
              "index.php?content-phone-muinews-getcontentrade",
              getcontentgradedata
            );
          }
        } else {
          Message.error({
            background: true,
            content: "新闻阅读失败，请稍后重试！",
          });
          return;
        }
      });
  }
};

// 获取学习积分，进行文件通知学习
const getcourseradenew = async () => {
  if (!userid.value) {
    router.replace({ path: "/Login" });
    return;
  } else {
    let data = {
      userid: userid.value,
      column:
        "contentid,contenttitle,contentthumb,contentusername,contentinputtime,contentwatch,contentfavor,clogtime,contentzt,contentpdf",
      lastpage: 1,
      xwfl: 1,
      catid: 22,
    };
    await _axios
      .post("index.php?content-phone-muislider-getContentsBycatid", data)
      .then(async (resp) => {
        if (resp.statusCode == 200) {
          let contents = resp.contents.data;
          let index = contents?.length
            ? Math.floor(Math.random() * contents?.length) < contents?.length
              ? Math.floor(Math.random() * contents?.length)
              : 0
            : 0;
          let content = contents[index];
          let contentid = content.contentid;
          let title = content.contenttitle;
          let watchandfavordata = { userid: userid.value, contentid };
          let getcontentgradedata = { userid: userid.value, title };
          await _axios.post(
            "index.php?content-phone-muinews-findbannerforwatchandfavor",
            watchandfavordata
          );
          await _axios.post(
            "index.php?content-phone-muinews-getcontentrade",
            getcontentgradedata
          );
          let timecount =
            parseInt(taskcoursemax.value) + Math.floor(Math.random() * 10);
          let getcourseradenewdata = {
            userid: userid.value,
            title,
            timecount,
            timejfsj: timecount,
          };
          await _axios
            .post(
              "index.php?content-phone-muinews-getcourseradenew",
              getcourseradenewdata
            )
            .then((resp) => resp.statusCode == 200);
        } else {
          Message.error({
            background: true,
            content: "获取学习内容失败，请稍后重试！",
          });
          return;
        }
      });
  }
};

// 延迟函数
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const handleStudy = async () => {
  Spin.show();
  if (!tasklogin.value) await getlogingrade();
  if (taskcontent.value < taskcontentmax.value) await getcontentgrade();
  if (taskcourse.value < taskcoursemax.value) await getcourseradenew();
  Spin.hide();
  Message.success("学习完成！");
  getsetting(userid.value);
};

onMounted(() => {
  if (!userid.value) {
    router.replace({ path: "/Login" });
    return;
  }
  getsetting(userid.value);
});
</script>

<style scoped>
div {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
</style>
