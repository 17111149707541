<template>
  <transition name="fade">
    <div>
      <div v-for="questionType in answerList.list" :key="questionType.items">
        <div v-if="questionType.title != '问答题'" class="can_select">
          <div>
            <Divider orientation="left">{{ questionType.title }}</Divider>
          </div>
          <div
            v-if="
              questionType.title == '单选题' || questionType.title == '判断题'
            "
          >
            <div :style="{ maxWidth: '560px' }">
              <Grid :col="6" :border="false" center square>
                <template
                  v-for="({ id, qon, qasw }, index) in questionType.items"
                  :key="'question_' + id"
                  ><GridItem v-if="index % 5 == 0">
                    {{ index + 1 }}-{{
                      index + 5 > questionType.items.length
                        ? questionType.items.length
                        : index + 5
                    }} </GridItem
                  ><GridItem
                    ><Poptip
                      word-wrap
                      :content="qon"
                      transfer
                      placement="right-start"
                    >
                      <Button> {{ qasw }}</Button>
                    </Poptip></GridItem
                  ></template
                >
              </Grid>
            </div>
          </div>
          <div v-else>
            <Space wrap direction="vertical" type="flex">
              <template
                v-for="({ id, qon, qasw }, index) in questionType.items"
                :key="'question_' + id"
                ><Poptip
                  word-wrap
                  :content="qon"
                  transfer
                  placement="right-start"
                >
                  <Button> {{ index + 1 }}、{{ qasw }}</Button>
                </Poptip>
              </template>
            </Space>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup>
import axios from "axios";
import qs from "qs";
import { onMounted, ref, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const _axios = axios.create({
  baseURL: "//yun1.hbgs.online/kaoshi",
  timeout: 3000,
  headers: { "content-type": "application/x-www-form-urlencoded" },
});
_axios.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => Promise.reject(error)
);
_axios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);
const route = useRoute();
const router = useRouter();
const userid = ref(0);
const basicid = ref(route.meta.basicid);
const basicknows = ref(0);
const ehtype = ref(0);
const answerList = reactive({
  list: { list1: [], list2: [], list3: [], list6: [], list7: [] },
});
const convertBars = (e, t) => {
  if (!e) return;
  let i = {
      list1: [],
      list2: [],
      list3: [],
      list6: [],
      list7: [],
    },
    s = e.examsessionsetting.examsetting.questypelite,
    n = e.examsessionquestion.questions;
  for (let r in s)
    if (1 == s[r]) {
      let e = [],
        s = 0;
      for (let t in n[r])
        s++,
          e.push({
            id: n[r][t]["questionid"],
            tid: s,
            qon: strReplace(entityToString(n[r][t]["question"])),
            qasw: n[r][t]["questionanswer"],
          });
      switch (r) {
        case "1":
          i.list1 = {
            title: t[r].questype,
            items: e,
          };
          break;
        case "2":
          i.list2 = {
            title: t[r].questype,
            items: e,
          };
          break;
        case "3":
          i.list3 = {
            title: t[r].questype,
            items: e,
          };
          break;
        case "6":
          i.list6 = {
            title: t[r].questype,
            items: e,
          };
          break;
        case "7":
          i.list7 = {
            title: t[r].questype,
            items: e,
          };
          break;
        default:
          continue;
      }
    }
  return i;
};

const entityToString = (e) => {
  var t = document.createElement("div");
  t.innerHTML = e;
  var i = t.innerText || t.textContent;
  return i;
};
const strReplace = (e) => {
  var t = e,
    i = "</p>[^<]*?<p>";
  return (
    (t = t.replace(new RegExp(i, "g"), "<br>")),
    (t = t.replace("<p>", "")),
    (t = t.replace("</p>", "")),
    t
  );
};

const findexampaperByRedis = async (userid, basicid, ehtype) => {
  if (!userid || !basicid || !ehtype) return;
  let data = {
    userid,
    basicid,
    ehtype,
  };
  await _axios
    .post("index.php?content-phone-muinews-findexampaperByRedis", data)
    .then((resp) => {
      if (resp.statusCode == 200) {
        answerList.list.length = 0;
        answerList.list = convertBars(resp.sessionvars, resp.questype);
      }
    });
};

onMounted(() => {
  userid.value = route.meta.userid;
  basicid.value = route.meta.basicid;
  basicknows.value = route.meta.basicknows;
  ehtype.value = route.meta.ehtype;
  (!userid.value || !basicid.value || !ehtype.value) &&
    router.replace({ path: "/Login" });
  findexampaperByRedis(userid.value, basicid.value, ehtype.value);
});
watch(
  () => route.meta,
  async (newValue) => {
    userid.value = newValue.userid;
    basicid.value = newValue.basicid;
    basicknows.value = newValue.basicknows;
    ehtype.value = newValue.ehtype;
    (!userid.value || !basicid.value || !ehtype.value) &&
      router.replace({ path: "/Login" });
    findexampaperByRedis(userid.value, basicid.value, ehtype.value);
  }
);
</script>
<style scoped>
.can_select {
  user-select: text;
  -ms-user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
}
</style>
