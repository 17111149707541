<template>
  <transition name="fade">
    <div>
      <h2>全部题库</h2>
      <div>
        <Input
          v-model="searchKey"
          search
          clearable
          placeholder="输入问题以查找..."
        />
      </div>
      <div v-if="listall">
        <Card
          dis-hover
          v-for="({ questype, qon, qos, qanswer }, index) in questionList"
          :key="'quesion_' + index"
          style="margin: 8px 0"
        >
          <div>
            {{ index + 1 }}、<Tag type="border" color="blue">{{ questype }}</Tag
            >{{ qon }}
          </div>
          <div v-html="qos"></div>
          <div>
            答案：<span class="answer">{{ qanswer }}</span>
          </div>
        </Card>
      </div>
      <div v-else>
        <div v-if="resultList.length > 0">
          <Card
            dis-hover
            v-for="({ qon, qos, qanswer }, index) in resultList"
            :key="'quesion_' + index"
            style="margin: 8px 0"
            class="can_select"
          >
            <div v-html="index + 1 + '、' + qon"></div>
            <div v-html="qos"></div>
            <div>
              答案：<span class="answer">{{ qanswer }}</span>
            </div>
          </Card>
        </div>
        <div v-else>
          <Exception
            type="500"
            title="无结果"
            desc="没有找到相关题目，请重新输入关键字。"
          />
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup>
import axios from "axios";
import qs from "qs";
import { onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

document.oncontextmenu = new Function("event.returnValue=false;");
document.onselectstart = new Function("event.returnValue=false;");
document.onkeydown = function () {
  if (event.ctrlKey) return false;
};

const _axios = axios.create({
  baseURL: "//yun1.hbgs.online/kaoshi",
  timeout: 3000,
  headers: { "content-type": "application/x-www-form-urlencoded" },
});
_axios.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => Promise.reject(error)
);
_axios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);
const route = useRoute();
const router = useRouter();
const userid = ref(0);
const basicid = ref(route.meta.basicid);
const basicknows = ref(0);
const ehtype = ref(0);
const questionList = reactive([]);
const resultList = reactive([]);
const listall = ref(true);
const searchKey = ref("");
const barsconvert = (e) => {
  if (!e) return;
  let t = e.questions,
    i = [];
  for (let s in t)
    (i = {
      id: t[s]["questionid"],
      questype: e.questype.questype,
      qon: strReplace(entityToString(t[s]["question"])),
      qos: t[s]["questionselect"].replace(/&lt;[^&gt;]+&gt;/g, ""),
      qanswer: strReplace(entityToString(t[s]["questionanswer"])),
    }),
      questionList.push(i);
};

const entityToString = (e) => {
  var t = document.createElement("div");
  t.innerHTML = e;
  var i = t.innerText || t.textContent;
  return i;
};
const strReplace = (e) => {
  var t = e,
    i = "</p>[^<]*?<p>";
  return (
    (t = t.replace(new RegExp(i, "g"), "<br>")),
    (t = t.replace("<p>", "")),
    (t = t.replace("</p>", "")),
    t
  );
};
const brightKeyword = (e) => {
  if (!e) return;
  let t = searchKey.value;
  return -1 !== e.indexOf(t)
    ? e.replace(new RegExp(t, "g"), `<span class='light'>${t}</span>`)
    : e;
};
const getlessonquestion = async (basicid, knowsid, qutype) => {
  if (!basicid || !knowsid || !qutype) return;
  let data = {
    basicid,
    knowsid,
    qutype,
  };
  await _axios
    .post("index.php?content-phone-muiphone-getlessonquestion", data)
    .then((resp) => {
      barsconvert(resp.args);
    });
};
const handleSearch = (value) => {
  if (value != "") {
    listall.value = false;
    let lists = JSON.parse(JSON.stringify(questionList));
    resultList.length = 0;
    lists.forEach((item) => {
      if (item.qon.indexOf(value) > -1 || item.qos.indexOf(value) > -1) {
        resultList.push(item);
      }
    });
    resultList.map((item) => {
      item.qon = brightKeyword(item.qon);
      item.qos = brightKeyword(item.qos);
    });
  } else {
    listall.value = true;
  }
};

onMounted(() => {
  document.title = "全部题库";
  userid.value = route.meta.userid;
  basicid.value = route.meta.basicid;
  basicknows.value = route.meta.basicknows;
  ehtype.value = route.meta.ehtype;
  if (!userid.value || !basicid.value || !ehtype.value)
    router.replace({ path: "/Login" });
  else {
    for (let knowid of basicknows.value) {
      for (let qutype of [1, 2, 3, 6, 7]) {
        getlessonquestion(basicid.value, knowid, qutype);
      }
    }
  }
});
watch(
  () => route.meta,
  async (newValue) => {
    if (!newValue.basicid || newValue.basicknows.length == 0) return;
    userid.value = newValue.userid;
    basicid.value = newValue.basicid;
    basicknows.value = newValue.basicknows;
    ehtype.value = newValue.ehtype;
    if (!userid.value || !basicid.value || !ehtype.value)
      router.replace({ path: "/Login" });
    else {
      questionList.length = 0;
      for (let knowid of basicknows.value) {
        for (let qutype of [1, 2, 3, 6, 7]) {
          getlessonquestion(basicid.value, knowid, qutype);
        }
      }
    }
  }
);
watch(searchKey, (newValue) => {
  handleSearch(newValue);
});
</script>
<style>
.answer {
  color: red;
  font-size: 20px;
  vertical-align: middle;
}
.light {
  color: red;
  background-color: yellow;
  font-weight: bold;
}
</style>
