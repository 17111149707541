<template>
  <transition name="fade">
    <div>
      <div class="ivu-mt-8">
        <Card
          class="yiyan"
          shadow
          :style="{
            height: '550px',
            background:
              'url(' +
              imgurls[Math.floor(Math.random() * imgurls.length)] +
              ') 50%/cover no-repeat',
          }"
        >
          <div class="yiyan-body ivu-pt">
            <span>{{ today.format("YYYY.MM dddd") }}</span>
            <p class="yiyan-day">{{ today.format("D") }}</p>
            <Divider :style="{ opacity: 0.4 }" />
            <p class="yiyan-text">
              {{ yiyan.data.hitokoto }}
              <span class="yiyan-author">{{
                yiyan.data.from_who == null ? "" : `- ${yiyan.data.from_who}`
              }}</span>
            </p>
          </div>
        </Card>
      </div>
    </div>
  </transition>
</template>
<script setup>
import { reactive, ref } from "vue";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import axios from "axios";

document.title = "欢迎使用";

const today = dayjs().locale("zh-cn");

const yiyan = reactive({ data: "" });

const _axios = axios.create();
_axios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);

_axios
  .get(`https://v1.hitokoto.cn/?c=d&c=i&c=j&c=k`)
  .then((resp) => (yiyan.data = resp));

const imgurls = ref([
  "https://images.pexels.com/photos/3184306/pexels-photo-3184306.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "http://smallest.com.cn/api/bingpic.php",
]);
</script>
<style scoped>
.yiyan::before {
  content: "";
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 20, 0, 0.7));
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
}
.yiyan-body {
  margin-top: 100px;
  position: relative;
  display: flex;
  padding: 0 20%;
  flex-direction: column;
  height: 300px;
  align-items: center;
  z-index: 1;
  color: #ffffffe6;
  text-align: center;
  opacity: 1;
}
.yiyan-day {
  font-size: 66px;
}
.yiyan-text {
  width: 100%;
  font-size: 18px;
  margin: 20px 20%;
  color: #ffffffe6;
  text-align: center;
  opacity: 1;
  user-select: text;
  -ms-user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
}
.yiyan-author {
  display: block;
  clear: both;
  opacity: 0.4;
  font-size: 12px;
  font-weight: 400;
}
</style>
