<template>
  <transition name="fade">
    <div class="login-container">
      <div class="login-container-img">
        <div
          class="ivu-exception-img-element"
          style="background-image: url('/binge watching.svg')"
        ></div>
      </div>
      <div class="login-container-content ivu-exception-content">
        <Card class="login ivu-pb ivu-pt" shadow>
          <div class="title">
            <h2>用户登录</h2>
          </div>
          <Login @on-submit="handleSubmit">
            <UserName
              name="username"
              :rules="usernameRule"
              placeholder="请输入工号"
            />
            <Password name="password" :rules="passwordRule" enter-to-submit />
            <Submit />
          </Login>
        </Card>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { Message, Spin } from "view-ui-plus";
import axios from "axios";
import qs from "qs";
document.title = "用户登录";
const _axios = axios.create();
_axios.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => Promise.reject(error)
);
_axios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);
const usernameRule = ref([
  {
    required: true,
    message: "账户不能为空！",
    trigger: "blur",
  },
  {
    pattern:
      /^((jh063[0-5])|jc1536|004283|041650|041588|041635|JH0236|041170)$/i,
    message: "用户未授权，请更换授权用户登录！",
    trigger: "blur",
  },
]);
const passwordRule = ref([
  {
    required: true,
    message: "密码不能为空！",
    trigger: "blur",
  },
  { min: 6, message: "密码不能少于6位", trigger: "change" },
]);

const router = useRouter();

let users = reactive({ data: {} });

router.beforeEach((to, from, next) => {
  if (to.name === "home") {
    to.meta = users.data;
    document.title = "欢迎";
  }
  next();
});

const handleSubmit = async (valid, { username, password }) => {
  if (valid) {
    let data = {
      args: { username: username, userpassword: password },
      userlogin: "1",
      userhash: "0.640034068151828",
    };
    Spin.show();
    await _axios
      .post("//yun1.hbgs.online/kaoshi/index.php?user-phone-logmui", data, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((rsp) => {
        if (rsp.statusCode === 200) {
          //登录成功
          users.data = rsp.users;
          Message.success(rsp.message);
          router.replace({ name: "home" });
        } else {
          //登录失败
          Message.error(rsp?.message || "登录失败，请重试！");
        }
      });
    Spin.hide();
  }
};
</script>

<style>
.login-container {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.login-container-img {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
@media screen and (max-width: 768px) {
  .login-container-img {
    display: none;
  }
  .login {
    margin: 64px auto !important;
  }
}
.login-container-content {
  flex: auto;
}
.login {
  width: 320px;
}
.title {
  padding: 0 0 20px 0;
}

.auto-login {
  margin-bottom: 24px;
  text-align: left;
}

.auto-login a {
  float: right;
}
</style>
