import { createApp } from "vue";
import ViewUIPlus from "view-ui-plus";
import App from "./App.vue";
import "view-ui-plus/dist/styles/viewuiplus.css";
import { createRouter, createWebHashHistory } from "vue-router";

import Index from "./components/Index.vue";
import LoginForm from "./components/LoginForm.vue";
import WelcomePage from "./components/WelcomePage.vue";
import LessonPage from "./components/LessonPage.vue";
import ExamPage from "./components/ExamPage.vue";
import NotFound from "./components/NotFound.vue";

const routes = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      // name: "home",
      component: Index,
      props: true,
      children: [
        {
          path: "",
          name: "home",
          component: WelcomePage,
        },
        {
          path: "lesson",
          name: "lesson",
          component: LessonPage,
        },
        {
          path: "exam",
          name: "exam",
          component: ExamPage,
        },
      ],
    },
    {
      path: "/Login",
      component: LoginForm,
    },
    {
      path: "/:error*",
      name: "404",
      component: NotFound,
    },
  ],
});

createApp(App).use(routes).use(ViewUIPlus).mount("#app");
